import React, { useEffect, useState, useRef } from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AiOutlinePrinter,AiOutlineEdit } from 'react-icons/ai';
import { MdOutlinePaid } from 'react-icons/md';
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { SiSitecore } from "react-icons/si";

import {getCompanyById} from '../../utils/tenantManager.js'
import {arrayNameByLang,printableDiv} from '../../utils/global.js'
import { useFetch,useLogs,useInvoice, useCurrent } from '../../hooks';
import { ConfirmDelete,MwButton, MwSpinner } from '../ui';
import InvoiceHeader from './show/InvoiceHeader.jsx';
import InvoiceBody from './show/InvoiceBody.jsx';
import InvoiceFooter from './show/InvoiceFooter.jsx';
import {  useTranslation } from 'react-i18next';
import '../../handle-head-list.css';

const ShowInvoice = ({isPrint=false,isDelivery=false}) => {
    const divPrintRef = useRef();
    const [print,setPrint] = useState(false);
    const [t] = useTranslation('global')
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice();

    const [showConfirmModal,setShowConfirmModal] = useState(false);
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();

    const lang = useSelector(state => state.lang);
    const invoiceSettings = useSelector(state => state.settings).quoteSettings;
    const company = getCompanyById(params.companyId);
    const companyName =  arrayNameByLang(company?.name,lang.value)?.text || arrayNameByLang(company?.name,'ar')?.text;
    const companyDesc =  arrayNameByLang(company?.desc,lang.value)?.text;
    const companyAddress =  arrayNameByLang(company?.mainAddress,lang.value)?.text;
    const companyPhone =  company?.mainPhone;
    const companyEmail =  company?.mainEmail;
    const companyWebsite =  company?.websiteUrl;
    const vatNo = company.vatNumber;
    const commercialRegisteration = company?.commercialRegisteration;
    const SETTINGTerms = invoiceSettings.INV_TERMS_PRINT
    const SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = invoiceSettings?.SHOW_INVOICE_PRODUCT_DESCRIPTION || 'false';
    const SETTING_INV_WITH_HEADER_FOOTER = invoiceSettings?.INV_WITH_HEADER_FOOTER || 'false';
    const SETTING_INV_QTY_DAYS = invoiceSettings?.INV_QTY_DAYS || 'false';
    const SETTING_SHOW_TERMS_ON_PRINT = invoiceSettings.SHOW_TERMS_ON_PRINT
    const SETTING_INV_SHOW_ITEM_T_A_VAT = invoiceSettings.INV_SHOW_ITEM_T_A_VAT
    const SETTING_INV_FONT_COLOR = invoiceSettings.INV_FONT_COLOR
    const SETTING_INV_UOM_SHOW = invoiceSettings.INV_UOM_SHOW
    const INV_CURRENCY = invoiceSettings?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'
    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    const {data:invData,loading:invLoading,error} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/show/${params.id}`)
    const {data:customerData,fetchData:fetchCustomer,} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/show/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${invData?.customer_id}`,false);


    useEffect(() => {
        if(invData){
            invData?.customer_id && fetchCustomer();
        }
    },[invData])
    
    const setLogsHandler =  (itemEn='delete',itemAr='حذف') => {
        const _data = {
            moduleName: "SALES",
            resourceName: "QUOTES",
            eventName:"DELETE",
            logContentEn: `${itemEn} quote No: ${invData.inv_no}`,
            logContentAr: `${itemAr} عرض سعر برقم : ${invData.inv_no}`,
        }
        postDataHandler(_data);
    }

    const deleteConfirm = async() => {
        const urlDeleteReq = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/delete/${params.id}`;
        const res = await axios.delete(urlDeleteReq);
        if(res.data){
            setLogsHandler();
            navigate(`/${params.tenant}/quotes`);
        }
        console.log(`Error deleting`);
    }

    const deleteHandler = () => {
        setShowConfirmModal(true);
    }

    const handlePrint = () => {
        setLogsHandler('print','طباعة');
        setPrint(true)
        printableDiv(divPrintRef,lang?.value)
    };
    
    return (
        <>
            { !invLoading  ? 
            <div>
                <ConfirmDelete
                    title={`هل انت متاكد من حذف عرض السعر`}
                    msg={`هل انت متاكد من حذف عرض السعر`}
                    onShow={showConfirmModal}
                    setOnShow={setShowConfirmModal}
                    onDeleteHandler={deleteConfirm}
                />
                <div className='flex py-2 fixed z-50 bg-slate-100 px-2 rounded-lg '>
                    <MwButton 
                        size = 'sm'
                        inGroup = {true}
                        type = 'secondary' 
                        onClick={handlePrint}>
                            <AiOutlinePrinter size={16}/> طباعة
                    </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'secondary-inline-end' 
                            onClick={()=>{
                                navigate(`/${params.tenant}/quotes/edit/${params.id}`)
                            }}>
                                <AiOutlineEdit size={16}/> تعديل 
                    </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'deleteBtn-inline' 
                            onClick={  deleteHandler }>
                                <AiOutlinePrinter size={16}/> حذف 
                    </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'saveBtn' 
                            onClick={ () =>{} }>
                                <MdOutlinePaid size={16}/> انشاء فاتورة لعرض السعر
                    </MwButton>
                </div>
                <div className='bg-slate-50 p-3 mt-2 rounded-lg'>
                    { 
                    // customerData?.data && 
                    <div id='printableContent'  ref={divPrintRef} className='p-5 mt-2   text-sm '>
                        <table className='w-full  '>
                        <thead><tr><td>
                            <div class="header-space">&nbsp;</div>
                        </td></tr></thead>
                        <tbody><tr><td>
                            <div class="  ">
                            <InvoiceHeader
                                logo = {company.logo} 
                                companyName={companyName}
                                companyPhone = {companyPhone}
                                companyEmail = {companyEmail}
                                companyDesc={companyDesc}
                                vatNo = {vatNo}
                                commercialRegisteration = {commercialRegisteration}
                                inv_no = {invData.inv_no}
                                inv_title = {invData.title}
                                inv_title_ar = {invData.title_ar}
                                inv_date = {invData.inv_date}
                                inv_date_due = {invData.inv_date_due}
                                companyAddress = {companyAddress}
                                invoiceSettings = {invoiceSettings}
                            />
                            <InvoiceBody
                            customerName = {invData.customer_name}
                            customer = {customerData?.data}
                            salesmanName = {invData.salesman_name}
                            invProducts = {invData.quote_details}
                            isDelivery = {isDelivery}
                            formatter = {formatter}
                            SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                            SETTING_INV_QTY_DAYS = {SETTING_INV_QTY_DAYS}
                            SETTING_INV_UOM_SHOW = {SETTING_INV_UOM_SHOW}
                            SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = {SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION}
                            SETTING_INV_SHOW_ITEM_T_A_VAT = {SETTING_INV_SHOW_ITEM_T_A_VAT}
                            />
                            <InvoiceFooter
                                totalAmount = {invData.total_amount}
                                vatAmount = {invData.vat_amount}
                                discountAmount = {invData.discount_amount}
                                netAmount = {invData.net_amount}
                                SETTINGTerms = {SETTINGTerms}
                                SETTING_PRICE_INCLUDE_VAT = {invoiceSettings.SETTING_PRICE_INCLUDE_VAT}
                                SETTING_INV_VAT_PERCENT = {invoiceSettings.INV_VAT_PERCENT}
                                SETTING_SHOW_TERMS_ON_PRINT = {SETTING_SHOW_TERMS_ON_PRINT}
                                SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                                isDelivery={isDelivery}
                                currentLang = {lang}
                                formatter = {formatter}
                                invNotes = {invData.notes}
                                invTerms = {invData.terms}
                            />
                        </div>
                        </td></tr></tbody>
                        <tfoot><tr><td>
                            <div class="footer-space">&nbsp;</div>
                        </td></tr></tfoot>
                        </table>
                        <div className='header w-full '>
                            <div dir='rtl' className=' text-center pb-2 font-bold text-lg'> <span style={{color:SETTING_INV_FONT_COLOR}}> عرض سعر  - Quotation Proposal</span> </div>
                            <div className=' pb-5  flex justify-between    gap-16'>
                                <div className=' max-w-64 content-center   gap-1 '>
                                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={company.logo} className='w-full' /></div> }
                                    
                                </div>
                                <div className='w-96 mx-7  content-center  ' dir='rtl' >
                                        <div className='border p-2 font-bold py-2 text-sm text-center' >{companyName}</div>
                                        <div className='flex flex-col  text-xs text-gray-500'>
                                            <div className='border p-2 flex items-center justify-between gap-3'>
                                                <span>   سجل تجاري:</span>
                                                <span className='font-bold'>{commercialRegisteration}</span>
                                                <span>:.C.R </span>
                                            </div>
                                            <div className='border p-2 flex items-center justify-between gap-3'>
                                                <span>  الرقم الضريبي:</span>
                                                <span className='font-bold'>{vatNo}</span>
                                                <span>:VAT NO</span>
                                            </div>
                                            { companyAddress && <div className='border p-2 flex items-center justify-between gap-3'>
                                                <span>   العنوان:</span>
                                                <span className='font-bold  text-center'>{companyAddress}</span>
                                                <span>:Address</span>
                                            </div>}
                                        </div>
                                </div>
                            </div>
                            <hr className='py-2' style={{  borderColor:SETTING_INV_FONT_COLOR || '#000000'}} />
                        </div>
                        <div className={`footer w-full   pt-3  text-xs`} >
                        <hr className='py-2'  style={{  borderColor:SETTING_INV_FONT_COLOR || '#000000'}} />
                        <div className='w-full   px-5 pt-2 flex gap-5 justify-between'>
                            <div className='text-center  flex gap-1  items-center' ><span style={{color:SETTING_INV_FONT_COLOR}}><FiMapPin  /></span> {companyAddress}</div>
                            <div className='flex gap-1 items-center'><span style={{color:SETTING_INV_FONT_COLOR}}> <FaPhoneSquareAlt /> </span> <span className='pt-1'>{companyPhone}</span>  </div>
                            <div>
                                <div className='flex gap-1'><span style={{color:SETTING_INV_FONT_COLOR}}> <SiSitecore /> </span> {companyWebsite}</div>
                                <div className='flex gap-1'><span style={{color:SETTING_INV_FONT_COLOR}}> <MdOutlineMailOutline /> </span>{companyEmail}</div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    }
                </div>
            </div> : 
            <MwSpinner/> 
            }
        </>
    )
}

export default ShowInvoice