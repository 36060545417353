import React from 'react'

const NoPayment = () => {
    return (
        <div className='p-5'>
            <div className='flex flex-col justify-center items-center gap-5'>
                <div className='border-b pb-3'>
                    <h5>عزيزي العميل نعتذر عن وقف الخدمة لعدم تجديد الاشتراك</h5>
                    <p>
                        لتجديد الاشتراك من فضلك تواصل مع فريق المبيعات 
                        عبر القنوات التالية
                    </p>
                </div>
                <div className='w-1/2 flex flex-col'>
                    <div className='w-full flex justify-between items-center '>
                        <span>جوال/واتساب</span> 
                        <span>00966539611960</span>
                    </div> 
                    <div className='w-full flex justify-between items-center '>
                        <span>البريد الالكتروني</span>
                        <span>sales@business-cube.sa</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoPayment