import React,{useState,useEffect,useRef} from 'react'
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import List from '@editorjs/list';

import InvFormListItem from '../../invoice/InvFormListItem'
import { MwButton } from '../../ui'
import  MwTabView from '../../ui/tab/MwTabView'
import  MwTabViewHead from '../../ui/tab/MwTabViewHead'
import  MwTabViewBody  from '../../ui/tab/MwTabViewBody'
// import {updateSettings} from '../../../store/settingSlice'
// import { useDispatch } from 'react-redux';


const InvFormBody = ({
    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION,
    SETTING_INV_QTY_DAYS,
    SETTING_INV_UOM_SHOW,
    initalProductItem,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    invNotes,
    invTerms,
    setInvNotes,
    setInvTerms
}) => {
    const [tabActive,setTabActive] = useState("items");

    const ejInstanceNotes = useRef();

    const initEditorNotes = () => {
        const editor = new EditorJS({
            /**
             * Id of Element that should contain Editor instance
             */
            holder: 'notes-editorjs',
            // placeholder: 'من فضلك ادخل ملاحظاتك علي عرض السعر',
            data: invNotes && JSON.parse(invNotes),
            onReady:()=>{
                // ejInstanceNotes.current = editor
                ejInstanceNotes.current = editor;
            },
            onChange:async()=>{ 
                let content = await editor.saver.save();
                setInvNotes(content)
                console.log(content)
            },
            autofocus: true,
            tools: { 
                header: {
                    class: Header,
                    inlineToolbar: ['link'],
                  }, 
                list: {
                    class: List,
                    inlineToolbar: true,
                  }, 
                }, 
            i18n: {
                /**
                 * Text direction
                 */
                    // direction: 'ltr',
                },
            });
    }
    const ejInstanceTerms = useRef();

    const initEditorTerms = () => {
        const editor = new EditorJS({
            /**
             * Id of Element that should contain Editor instance
             */
            holder: 'terms-editorjs',
            data: invTerms && JSON.parse(invTerms),
            // placeholder: 'الشروط والاحكام',
           
            onReady:()=>{
                ejInstanceTerms.current = editor
            },
            onChange:async()=>{ 
                let content = await editor.saver.save();
                setInvTerms(content)
                console.log(content)
            },
            autofocus: true,
            tools: { 
                header: Header, 
                list: List 
                }, 
            i18n: {
                /**
                 * Text direction
                 */
                    // direction: 'ltr',
                },
            });
    }

    useEffect(() => {
        if( tabActive === "notes" && ejInstanceNotes.current === null){
            initEditorNotes();
        } 
        if(tabActive === "terms" &&  ejInstanceTerms.current === null){
            initEditorTerms();
        } 
        return () =>{
            if(tabActive === "notes"){

                ejInstanceNotes?.current?.destroy();
                ejInstanceNotes.current = null;
            }
            if(tabActive === "terms"){

                ejInstanceTerms?.current?.destroy();
                ejInstanceTerms.current = null;
            }
        }
    },[tabActive]);

    const addSectionHandler = (e)=>{
        e.preventDefault()
        setProductList([...productList,{
            index: productList.length,
            productId:'section', 
            productName: '',
            productDesc: '',
            qty:0,
            qtyDays:0,
            price:'0',
            product_discount:0,
            product_net_total:0,
            product_net_total_with_vat:'0',
            product_vat: 0,
            total_price:0,
            unit_id:1,
        }]);
    }
    const addItemHandler = (e)=>{
        e.preventDefault()
        setProductList([...productList,{
            index: productList.length,
            productId:'', 
            productName: '',
            productDesc: '',
            qty:1,
            qtyDays:1,
            price:'0'
        }]);
    }
    return (
        <>
        <hr className='my-2' />
        <MwTabView>
            <MwTabViewHead  setTabActive={setTabActive} tabActive={tabActive} tabTitleList={[
                { label:"الاصناف", value:"items"},
                { label:"الملاحظات", value:"notes"},
                { label:"الشروط والاحكام", value:"terms"}
                ]} />
            
            <MwTabViewBody>
                
            <div id='inv-body' className={`h-96 overflow-y-auto     ${tabActive === 'items' ? 'block' : 'hidden'} ` }   >
            <div className='  '>
                    { initalProductItem.map((e,index)=>{
                        return <InvFormListItem
                                    key = {index}
                                    index = {index}
                                    reloadProductList = {reloadProductList}
                                    showModalProductHandle = {showModalProductHandle}
                                    productList = {productList}
                                    setProductList = {setProductList}
                                    formErrors = {index ===0 && formErrors}
                                    SETTING_INV_QTY_DAYS = {SETTING_INV_QTY_DAYS}
                                    SETTING_INV_UOM_SHOW = {SETTING_INV_UOM_SHOW}
                                    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = {SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION}
                                    />
                        })
                    }
                </div>
                    <div className='  flex justify-end gap-1 my-3'>
                        <MwButton  actionType="button" type="secondary" onClick={addSectionHandler} > اضف قسم </MwButton>
                        <MwButton  actionType="button" type="secondary" onClick={ addItemHandler} > اضف بند </MwButton>
                    </div>
                </div>
                <div id="notes-editorjs" className={`h-full	 p-4 bg-white  rounded-lg ${tabActive === 'notes' ? 'block' : 'hidden'}`} >
                
                </div>
                <div id="terms-editorjs" className={`h-full	 p-4 bg-white  rounded-lg ${tabActive === 'terms' ? 'block' : 'hidden'}`} >
                </div>
            </MwTabViewBody>
        </MwTabView>
        <hr />
        </>
    )
}

export default InvFormBody